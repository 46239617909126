nav {
    height: 6em;
}

/*nav container*/
.nav__container {

    display: flex;
    align-items: center;
    justify-content: space-between;
}


/*ul*/
.nav__item ul {
    display: flex;
    flex-direction: row;
    gap: 2em;
}

.nav__item li {
    list-style-type: none;
}

.nav__item a {
    text-decoration: none;
    color: var(--color--darkgreen);
    font-weight: 600;
    position: relative;
}

/*hover*/
.balls {
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    border-radius: 100%;
    box-shadow: 0 0 20px var(--color--yellow);
    background-color: var(--color--yellow);
    transition: ease-in-out 0.5s;
    margin-left: -0.85rem;
    opacity: 0;
}

.nav__item a:not(.active)::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.5em;
    width: 100%;
    height: 0.12em;
    background: var(--color--darkgreen);
    border-radius: 2em;
    transform: scaleX(0);
    transition: transform 0.5s;
}

.nav__item a:not(.active):hover::after {
    transform: scaleX(1);
}

/*active stage*/
.nav__item a.active {
    color: var(--color--darkgreen);
}

.nav__item a.active .balls {
    opacity: 1;
}


.nav__name a {
    color: var(--color--darkgreen);
    text-decoration: none;
}

/**/

.nav__btn__container {
    position: absolute;
    right: 0;
    overflow: hidden;
    width: 5em;
    height: 2em;
    border-radius: 2em;
    cursor: pointer;
    color: var(--color--wave);
    font-weight: 400;
    background-color: var(--color--darkgreen);
    z-index: 100;
}

.nav__btn {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.nav__btn1 {

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.nav__btn2{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;

}

.nav__hambuger__container {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 15;
    background-color: var(--color--darkgreen);
    color: var(--color--wave);
}
.nav__hambuger__content{
    height: 20vh;
    display: flex;
    flex-direction: column;
}
.nav__hambuger__close {
    width: 5em;
    height: 2em;

    position: absolute;
    right: var(--margin--normal);
    top: 2em;
    font-weight: 400;
    cursor: pointer;
}

.nav__hambuger__list {
    margin: var(--margin--normal);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2em;
    margin-top: 2.5em;
    gap:1em;
}
.nav__hambuger__list p{
    margin: 0;
}
.nav__hambuger__list a {
    color: var(--color--wave);
    text-decoration: none;

}
.nav__hambuger__list a.active{
    color: var(--color--orange)
}
.nav__hambuger__contact{
    margin: var(--margin--normal);
}
.nav__hambuger__contact a{
    color: var(--color--blue);
    text-decoration: none;
}

.nav__menu__balls{
    position: absolute;
    width: 0.75em;
    height: 0.75em;
    border-radius: 100%;
    box-shadow: 0 0 20px var(--color--yellow);
    background-color: var(--color--yellow);
}
.nav__hambuger__koi{
    position: absolute;
    transform: rotate(280deg);
    right: 20%;
    top: 75%;
    opacity: 0.7;
}
.hambuger {
    display: none;

}
.menu__fix{
    position: relative;
}
