/*home*/
.container {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.bg__lotus {
    position: fixed;
}

.bg__wave img {
    position: fixed;
    top: 0%;
    min-height: 100vh;
    min-width: 100vw;
    object-fit:cover;
    z-index: 1;
    overflow: auto;
}

.main__container {
    position: relative;
    z-index: 99;
    margin-left: var(--margin--normal);
    margin-right: var(--margin--normal);
}

/*title*/
.main__container__top {
    height: 75vh;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.main__container__motionChild {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: inherit;
    margin: 0;
    padding: 0;
}

.main__nav {
    margin-left: var(--margin--normal);
    margin-right: var(--margin--normal);
}

.main__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--margin--s);
    margin-left: var(--margin--normal);
    margin-right: var(--margin--normal);
}

.main__title h1 {
    font-family: 'Marck Script', cursive;
    font-size: 4em;
    margin-bottom: 0.5em;
    letter-spacing: 0.1em;
    text-align: center;
    color: var(--color--darkgreen);
}

.title__slogan {
    width: 70%;
    text-align: center;
    font-size: 1.25em;
}

.main__arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 15vh;
    cursor: default;
}

.main__arrow__content {
    position: relative;
    z-index: 10;
    display: flex;
    gap: 0.5em;
    font-size: 1.125em;
    font-weight: 500;
}

.main__arrowColor {
    position: absolute;
    box-shadow: 0 0 20px var(--color--yellow);
    background-color: rgba(252, 243, 83, 0.8);
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    margin-top: 0em;
    margin-left: -15.5em;
}

/*pictok*/
.pictok__mainContainer h2{
    color:var(--color--pictok--blue)
}
.topContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--margin--xs) var(--margin--s);
    min-height: 20vh;
    border-radius: 2em;
    gap: 8em;
    margin-top: 3em;
}

.pictok__topContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color--pictok--lightblue);
    padding: var(--margin--xs) var(--margin--s);

    border-radius: 2em;
    gap: 8em;
}

.pictokIntro__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25em;
}

.pictokIntro__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pictokIntro__name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pictokIntro__braille {
    position: absolute;
    margin-left: 0.75em;
    width: 10em;
}

.show {
    animation: showHideAnimation 8s infinite;
}

.hide {
    animation: showHideAnimation 8s infinite;
    animation-delay: 4s;
    opacity: 0;
}



@keyframes showHideAnimation {
    0% {
        opacity: 0;
    }

    45% {
        opacity: 1;
    }

    55% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.pictokIntro__title h1 {
    text-align: center;
}

.pictokIntro__title h2 {
    font-family: 'Baloo 2', sans-serif;
    font-size: 3em;
    padding: 0;
    margin: 0;
    color: var(--color--pictok--blue);
}

.pictokIntro__title h3 {
    font-size: 1em;
    color: var(--color--pictok--grey);
    padding: 0;
    margin: 0;
    margin-top: -1.75em;
}

.pictokIntro__logo img {
    width: 8em;
    height: auto;
}

.pictokIntro__logo {
    margin-bottom: 1em;
    margin-top: 0.5em;
    background-color: #ffffff6f;
    border-radius: 100%;
    width: 10em;
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*pictok main*/
.pictok__mainContainer {
    display: flex;
    margin-top: 2em;
}

.pictok__mainContainer h3 {
    margin: 0;
    font-size: 1.5em;
}

.pictok__mainTitle {
    min-width: 25%;
    padding-left: 1em;
}

.pictok__mainTitle h2 {
    font-size: 2em;
}

.pictok__subContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
    margin-left: var(--margin--m);
    margin-right: var(--margin--m);
}

.pictok__subContent h3 {
    text-align: center;
}

.pictok__mainContent {
    margin-top: 1.5em;
}

.pictok__summary__content {
    margin-bottom: 4em;
}

/*pictok summary*/
.pictok__role__container img{
    margin-top: 2em;    
}
.pictok__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}
.pictok__role{
    width: 100%;
    display: flex;
    gap: 2em;
    justify-content:center ;
    align-items: center;
    margin-top: 1em;
}
.pictok__role p{
    background-color: var(--color--pictok--lightblue);
    border-radius: 2em;
   width: 8em;
   height: 2.5em;
   display: flex;
   align-items: center;
   justify-content: center;

}
.pictok__features {
    display: flex;
    justify-content: space-between;
    gap: 2em;
    flex-direction: column;
    margin-bottom: 1em;

}

.pictok__features__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
    background-color: #c5e1f548;
    padding: 1em;
    border-radius: 2em;
}

.pictok__tap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-top: 0em;
    color: #201405;
}

.pictok__tap button {
    margin-top: 2em;
    background-color: transparent;
    border-radius: 2em;
    padding: 0 var(--margin--s);
    border: none;
    cursor: pointer;
}

/*pictok research*/
.pictok__research {
    display: flex;
    gap: 5em;
}

.pictok__research__comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
    min-width: 35%;
}

/*pictok structure*/
.pictok__lightbox__notes {
    margin: 0em;
    margin-top: 0.5em;
    font-size: 0.85em;
    text-align: right;
    color: var(--color--pictok--grey);
}

/*pictok style*/
.pictok__colorContainer {
    display: flex;
    flex-direction: column;
    background-color: #c5e1f548;
    padding: var(--margin--xs) var(--margin--s);
    border-radius: 2em;
    margin-top: 3em;
}

.pictok__colorPalette {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pictok__color__name {
    text-align: center;
    color: var(--color--pictok--grey);
    font-size: 0.85em;
}

.pictok__color__click {
    position: absolute;
    color: var(--color--pictok--blue);
    margin-top: -2.5em;
    margin-left: 4em;
    cursor: pointer;
}

.pictok__color__box {
    width: 8em;
    height: 8em;
    border-radius: 2em;
}

.pictok__jost {
    font-family: 'Jost', sans-serif
}

/*pictok structure*/
.pictok__persona__container{
    display: grid;
    grid-template-columns: repeat(2,48%);
    align-items: center;
    justify-content: center;
    gap: 4%;
}
.pictok__persona,
.pictok__persona__1,
.pictok__userflow {
    border: 0.2em solid #c5e1f548;
    object-fit: cover;
    border-radius: 2em;
    transition: border 0.3s ease;
    cursor: pointer;
    object-position: 0% 0%;
    width: 100%;
    height: 15em;
    margin-top: 3em;
    background-color: white;
    cursor:zoom-in
}


.pictok__persona:hover,
.pictok__persona__1:hover,
.pictok__userflow:hover {
    border: 0.2em solid var(--color--pictok--blue);
}

/*pictok design*/
.pictok__figma{
    position: relative;
}
.pictok__figma a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.pictok__figma button {
    margin-top: 3em;
    background-color: var(--color--pictok--blue);
    border-radius: 2em;
    padding: 0 var(--margin--s);
    border: 0.1em solid var(--color--pictok--lightblue);
    cursor: pointer;
    transition: border ease-in-out 300ms;
}

.pictok__figma button:hover {
    border: 0.1em solid var(--color--pictok--blue);
}

/*pictok challenge*/

.pictok__homes {
    width: 100%;
    margin-top: 3em;
}

/*pictok final*/
.pictok__final{
    margin-top: 3em;
}
.pictok__product__display {
    background-color: #c5e1f548;
    margin-left: -12.5%;
    margin-right: -12.5%;
    padding-left: 12.5%;
    padding-right: 12.5%;
}
.pictok__outcome{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 70vh;

}
.pictok__outcome__bg{
    margin-left: -12%;
    height: 70vh;
    width: 100%;
    position: absolute;
    z-index: 0;
    opacity: 0.3;
    object-fit: cover;
}
.pictok__product {

   display: grid;
   grid-template-columns: repeat(2,48%);
    gap: 4%;
    margin-top: 3em;
}
.pictok__app__content{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pictok__app__link button{
    width: 18em;
}
.pictok__product video {
    width:20em;
    background-color: var(--color--pictok--blue);
    border: 0.2em solid #c5e1f548;
    height: 28em;
    border-radius: 2em;
    position: relative;
    z-index: 12;
    background-color: white;
}

.pictok__product__item h3 {
    margin-bottom: 1em;
}

.pictok__product__music,
.pictok__product__flower {
    position: absolute;
    z-index: 0;
}

.music__1 {
    width: 1.5em;
    margin-left: 17em;
    margin-top: 13em;
}

.flower__2 {
    width: 6.5em;
    margin-top: 17em;
    margin-left: -3em;
    transform: rotate(-50deg) scaleX(-1);
}

.music__2 {
    width: 1.5em;
    margin-left: -3em;
    margin-top: 5em;
    transform: rotate(-0deg);
}

.flower__3 {
    width: 3em;
    margin-top: 16em;
    margin-left: 13.5em;
    transform: rotate(35deg) scaleX(-1);
}

.pictok__marketing{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pictok__marketing__title {
    margin-top: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pictok__brochure__container {
    display: flex;
}

.pictok__brochure {
    width: 100%;
    cursor: zoom-in;
    position: relative;
}
.pictok__promotional__item{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}
.pictok__promotional__item iframe{
    margin-top: 1em;
}
.pictok__outcome__pages{
    width: 100%;
    margin-top: 4em;
}
/*cc*/
.cc__mainContainer{
    background-color: #f4e9e4;
    margin: 0;
    padding-left: var(--margin--normal);
    padding-right: var(--margin--normal)
}
.cc__mainContainer h2{
    color: var(--color--cc--orange);
}
.cc__topContainer {
    border-radius: 2em;
    min-width: 20em;
}

.cc__carousel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cc__choose {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cc__choose ul {
    display: flex;
    gap: 1em;
    list-style: none;
    padding: 0;
    margin: 0;
}

.cc__choose li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em;
    width: 5em;
    padding: 0.25em 0em;
    border-radius: 2em;
    font-weight: 500;
    cursor: pointer;
}

.cc__process {
    width: 100%;
    margin-top: 2.5em;
    display: grid;
    grid-template-columns: repeat(3, 33%);
}

.cc__process img,
.cc__package img {
    display: block;
    margin: 0 auto;
    inline-size: 90%;
    border-radius: 2%;
}

.cc__package {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(3, 33%);
    margin-bottom: 4em;
    margin-top: 2.5em;
}

.cc__deco__1 {
    position: absolute;
    margin-left: 25em;
    margin-top: 15em;
    transform: rotate(-20deg);
    width: 7em;
    opacity: 0.7;
}

.cc__deco__2 {
    position: absolute;
    margin-left: -25em;
    margin-top: -3em;
    transform: rotate(40deg);
    width: 6em;
    opacity: 0.5;
}

.cc__name__span {
    padding: 0.3em
}

.cc__choose__2 li {
    margin-bottom: 0em;
    margin-top: 1em;
}

.cc__mainContainer__lessMargin h2 {
    margin-top: 0;
}

.cc__carousel h1{
    position: absolute;
    font-size: 6em;
    margin-top: 0;
    margin-left: 0;
    z-index: 10;
}
.cc__product {
    width: 23em;
    position: relative;
    z-index: 15;
}
/*infj*/
.infj__mainContainer h2{
    color: var(--color--infj--green);
}
.infj__topContainer{
    background-color: var( --color--infj--green);
    max-height: 70vh;
    color: var(--color--infj--lightgreen);
    position: relative;
}
.infj__topContainer video{
    height: auto;
    width: 100%;
    max-width:50em;
    object-fit: contain;
    border-radius: 2em;
}

.infj__topContainer h1{
    font-size: 3em;
}
.g__4 {
    margin-top: var(--margin--s);
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5%;
    grid-row-gap: 5%;
    justify-content: center;
    align-items: center;
    margin-bottom: 4em;
  }
  .g__4 img{
    inline-size: 100%;
  }

.infj__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}  
.infj__btn button {
    width: 15em;
    margin-top: 3em;
    background-color: var(--color--infj--lightgreen);
    border-radius: 2em;
    padding: 0 var(--margin--s);
    border: 0.1em solid var(--color--infj--lightgreen);
    cursor: pointer;
    transition: border ease-in-out 300ms;
}

.infj__btn button:hover {
    border: 0.1em solid  var(--color--infj--green);
}
/*mb*/
.mb__mainContainer h2{
    color: var(--color--mb--dark);
}
.mb__topContainer{
    background-color:var(--color--mb--dark);
}
.mb__btn button{
    background-color: var(--color--mb--dark);
    color: var(--color--mb--beige);
}

.mb__plan{
    width: 100%;
    margin-top: 3em;
    border-radius: 2em;
}
/*gg*/
.gg__mainContainer h2{
    color: var(--color--gg--dark);
}
.gg__topContainer{
    background-color: var(--color--gg--green);
    align-items: center;
    justify-content: center;
}
.gg__topContainer h1{
    font-size: 2em;
    text-align: center;
}
.gg__topContainer__item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: var(--margin--s);
}
.gg__topContainer__item img{
    border-radius: 1.5em;
    width: 10em;
}
.gg__top_img1 {
    position: absolute; border: 0.15em solid var(--color--gg--dark);
}
.gg__top_img2 {
    position: relative; border: 0.15em solid var(--color--gg--dark);
}
.gg__top__context{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--color--gg--dark);
    max-width: 40%;
}

.gg__summary{
    display: flex;
    gap: 2em;
}
.gg__topBtn button{
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.gg__btn button{
    background-color: var(--color--gg--green);
    color: var(--color--gg--dark);
}
.gg__anim{
    display:grid;
    grid-template-columns: repeat(3,30%);
    align-items:flex-end;
    justify-content: center;
    gap: 2%;
    margin-top: 3em;
    margin-bottom: 4em;
}
.gg__anim img{
    padding-bottom: 0.5em;
    padding-left: var(--margin--m);
}
.gg__mascot__item{
    display: flex;
    align-items:center;
    justify-content: center;
    margin-top: 2em;
    gap: 2em;
}
.gg__mascot__item img{
    width: 9em;
}
.gg__mascot__talk {
    width: 15em;
    padding:0 1.5em;
    background-color:#abcd9c3d;
    border: 0.25em solid var(--color--gg--green);
    border-radius: 5em;
}
/*pj card*/
.pjcard__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4em;
    position: relative;
}

.pjcard__cardContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.pjcard__grid {
    display: grid;
    grid-template-columns: repeat(3, 20vw);
    align-items: center;
    justify-content: center;
    border-radius: 2em;
    gap: 4em;
    width: 100%;
}

.pjcard__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 17em;
}

.pjcard__btn h3 {
    font-size: 6em;
    margin-top: 0.15em;
}

.pjcard__btn button {
    cursor: pointer;
    border-radius: 5em;
    background-color: rgba(250, 250, 250, 0.25);
    width: 10em;
    height: 2.5em;
    margin-top: 1em;
    font-weight: 600;
}

.pjcard__context {
    display: flex;
    flex-direction: column;
    margin-top: 10em;
    margin-left: 4em;
    position: relative;
    z-index: 10;
}
.pjcard__context p {
    max-width: 20em;
}
.pjcard__tags {
    display: flex;
    font-weight: bold;
    gap: 1em;

}

/*pictok*/
.pjcard__name1__1 {
    position: absolute;
    font-size: 8em;
    top:0;
    left:0;
    margin-left: -1.45em;
    margin-top: 0.25em;
}

.pjcard__name2__1 {
    position: absolute;
    font-size: 8em;
    top:0;
    right:0;
    margin-right: -1.65em;
    margin-top: 0.25em;
}

/*infj*/
.pjcard__name1__2 {
    position: absolute;
    font-size: 6em;
    top:0;
    left:0;
    margin-left: -2.4em;
    margin-top: 0.35em;
}

.pjcard__name2__2 {
    position: absolute;
    font-size: 6em;
    top:0;
    right:0;
    margin-right:-2.25em;
    margin-top: 0.35em;
}

/*cc*/
.pjcard__name1__3 {
    width: 20em;
    position: absolute;
    font-size: 5em;
    top:0;
    left:0;
    margin-left:-1.8em;
    margin-top: 0em;
}


/*memory Box*/
.pjcard__name1__4 {
    width: 20em;
    position: absolute;
    font-size: 6em;
    top:0;
    left:0;
    margin-left:-12vw;
    margin-top: -0.75em;
}


/**/
.pjcard__name1__5{
    position: absolute;
    font-size: 6em;
    top:0;
    left:0;
    margin-left: -2.5em;
    margin-top: 0.5em;
}
.pjcard__name2__5 {
    position: absolute;
    font-size: 6em;
    top:0;
    right:0;
    margin-right:-3em;
    margin-top:0.5em;
}


/**/
.pjcard__context__screen {
    display: none;
}

.re-padding{
    margin: 0;
    padding-left: var(--margin--normal);
    padding-right: var(--margin--normal);
}

/*Meida Screen*/
@media screen and (min-width: 1800px) {
  .main__container{
    margin-left: 20%;
    margin-right: 20%;
  }
.re-padding{
    margin: 0;
    padding-left: 20%;
    padding-right: 20%;
}
.pjcard__grid{
    gap: 0;
}
.pjcard__context p{
    margin-left: 4em;
}
.pictok__outcome__bg{
    margin-left: -22%;
}
.pjcard__name1__4 {
 
    margin-left:-6vw;

}

  }

@media screen and (max-width: 1024px) {

    /*Home*/
    .pjcard__grid {
        grid-template-columns: unset;
        gap: 1em;
    }

    .pjcard__btn {
        margin-top: 0em;
    }

    .project__hide {
        display: none;
    }

    .pjcard__context__screen {
        display: flex;
        flex-direction: column;
        padding-left: var(--margin--normal);
        padding-right: var(--margin--normal);
        margin-bottom: 2em;
    }

    .pjcard__tags {
        align-items: center;
        justify-content: center;
    }

    .pjcard__cardContainer {
        margin-top: 2em;
    }


    /*projects*/
    .pictok__topContainer {
        gap: 2em;
    }

    .pictokIntro__title h1 {
        display: none;
    }

    .pictok__mainContainer {
        display: flex;
        flex-direction: column;
        margin-top: 2em;
    }

    .pictok__mainTitle {
        padding-left: 0em;
    }

    .pictok__mainContent {
        margin-top: 0;
    }
    .cc__carousel h1{
        font-size: 5em;
    }

}

@media screen and (max-width: 768px) {


    /*projects*/
    .topContainer {
        flex-direction: column;
        padding: var(--margin--normal);
    }

    .pictokIntro__slogan {
        margin: 0 var(--margin--s);
    }

    .pictok__summary {
        flex-direction: column;
    }

    .pictok__research {
        display: flex;
        flex-direction: column;
    }

    .pictok__mainContainer h3 {
        font-size: 1.125rem;
    }
    .pictok__product{
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    .pictok__product__item{
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .pictok__app__link button{
       margin: 1em;
    }
    .cc__carousel h1{
        font-size: 3em;
        margin-top: -5.5em;
    }
    .cc__deco__1,.cc__deco__2{
       display: none;
    }

    .gg__top__context{
        max-width: 100%;
    }
    .gg__topContainer {
        gap: 1em;
    }
    .gg__top_img1{
        display: none;
    }
    .font-xs {
        font-size: 0.75rem;
    }

    .box__size__xs {
        width: 6rem;
        height: 6rem;
        border-radius: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .project__hide__s {
        display: none;
    }

    .hambuger {
        display: block;
    }

    .nav__item {
        display: none;
    }

    /*home*/
    .project__img__resize{
      width: 17em !important;
    }
    /*design*/
    .gallery__container{
        margin-left: -12.5%;
        margin-right: -12.5%;
        overflow-x: hidden;
    }
    .gallery__container h1 {
        font-size: 3em;
        margin-left: var(--margin--normal);
        margin-right: var(--margin--normal);
    }
    .gallery__box :hover{
        background-color: none;
        border: none;
    }
    .gallery__box {
        display: flex;
        flex-direction: column;
        gap: 2em;

        border-radius: 0;
    }
 
    .pictok__role{
        flex-direction: column;
        gap: 0;
    }
    .pictok__mainContainer h3 {
        font-size: 1rem;
    }

    .font-xs {
        font-size: 0.75rem;
    }
    .font-m{
        font-size: 1em;
    }
   
    .box__size__xs {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
    }

    .pictok__subContent {
        margin-left: var(--margin--s);
        margin-right: var(--margin--s);
    }
    .gg__anim {
       display: flex;
    }
    .gg__anim img{
        padding-left: 0;
    }
    .gg__mascot__item{
        gap: 0;
    }
    .gg__mascot__item img{
        width: 5em;
    }
    .cc__carousel h1{
        font-size: 2em;
    }
    .cc__topContainer{
        min-width: 5em;
    }
    .cc__choose ul{
        gap: 0.5em;
    }
    .cc__product{
        width: 15em;
    }
}