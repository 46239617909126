.transition__svg, .transition__background{
    width: 100vw;
    height: calc(100vh + 600px);
    top:-300px;
    left: 0;
    position: fixed;
    pointer-events: none;
    fill: var(--color--wave);
    z-index: 999;
}

.transition__background{
   overflow-x: clip;
}
.transition__container{
    overflow-x: hidden;
}
