.project__footer__container{

    width: 100%;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project__footer__container a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}
.project__footer__container button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 0.5em;
    width: 100%;
    height: 4em;
    border: none;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    padding: 0;
    
}
.project__footer__button1{
    border-radius: 2em 0 0 0 ;
}
.project__footer__button2{
    border-radius: 0 2em 0 0 ;
}

.project__arrow{
    margin-top: 0.8em;
}

/*general page footer*/
.page__footer{
    background-color: var(--color--darkgreen);
   
    z-index: 10;
    position: relative;
    border-radius: 2em 2em 0 0;
    margin-top: 4em;
    color: var(--color--wave);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: var(--margin--normal);
    padding-right: var(--margin--normal);
}

.page__name{
    display: flex;
    align-items: center;
    justify-content:center ;
   margin-top:0.2em;
   cursor: default;
   margin-left:0.5em
}
.page__name a{
    color: var(--color--wave);
    text-decoration: none;
}
.page__links{
    display: flex;
    align-items:center;
    justify-content: flex-end;
    gap: 5%;
}
.page__links img{
    width: 2.25em;
}