.contact__koi {
  width: 6vw;
  transform: rotate(270deg);
  position: absolute;
  top: 70%;
  left: 80%;
  opacity: 0.6;
  animation: koi-float 3s infinite ease-in-out, koi-fade 2s forwards;
}

@keyframes koi-float {
  0% {
    transform: rotate(270deg);
  }

  50% {
    margin-left: -1em;
    margin-top: -1em;
    transform: rotate(280deg);
  }

  100% {
    transform: rotate(270deg);
  }

}

@keyframes koi-fade {
  to {
    opacity: 0.8;
  }
}

.koi__balls__container {
  position: absolute;
  top: 68%;
  left: 78%;
  z-index: 15;
}

.koi__balls {
  width: 1vw;
  height: 1vw;
  border-radius: 100%;
  box-shadow: 0 0 20px var(--color--yellow);
  background-color: var(--color--yellow);
  transition: ease-in-out 0.5s;
  margin-left: -0.85rem;
  opacity: 0;

}

/**/
.contact__mainContent {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact__slogan{
  text-align: center;
}
.contact__list {
  margin-top: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
}

.contact__list img {
  width: 3.5em;
}

.contact__btn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.contact__btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.contact__btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 0.5em;
  width: 100%;
  height: 4em;
  font-weight: 400;
  color: var(--color--wave);
  background-color: var(--color--darkgreen);
  cursor: pointer;
}

.contact__btn1 {
  border-radius: 2em 0 0 0;
  border-right: 0.1em solid var(--color--blue);
  border-top: none;
  border-left: none;
  border-bottom: none;
}

.contact__btn2 {
  border-radius: 0 2em 0 0;
  border: none;
}