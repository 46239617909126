.pictok__switch {
    width: 7em;
    height: 2.5em;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: flex-start;
    border-radius: 2em;
    padding: 0.5em;
    cursor: pointer;
    border: 0.1em solid #C5E1F5;
  }
  
  .pictok__switch__dark {
    justify-content: flex-end;
  }
  
  .pictok__handle {
    width: 2.5em;
    height: 2.5em;
    border-radius: 40px;
    box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
  }
