/*.design__horizontal{
    height: 300vh;
    position: relative;
}
.design__carousel__container{
    position: fixed;
    top: 20%;
    overflow: hidden;
    margin-left: -12.5%;
    margin-right: -12.5%;
}
.design__scroll{
    position: fixed;
    top: 80%;
    right: var(--margin--normal)
}
.design__nav{
    position: sticky;
    top: 0;
}*/

/*nav*/
/*
.design__nav {
    position: fixed;
    bottom: 5%;
    width: 58%;
    z-index: 99;
    margin-left: var(--margin--normal);
    margin-right: var(--margin--normal);
    background-color: rgba(250, 250, 250, 0.85);
    border-radius: 2em;
    cursor: pointer;
}

.design__nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding-left: var(--margin--normal);
    padding-right: var(--margin--normal);
    list-style-type: none;
}

.design__nav li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    font-weight: 400;
}

.design__nav li span {
    padding: 0.2em;
    position: relative;
    color: transparent;
}

.design__nav li:hover .design__nav li span {
    color: var(--color--darkgreen);
}

.design__nav li p {
    position: relative;
}

.design__nav__c {
    position: absolute;
    padding: 0;
    margin-left: -1.4em;
    margin-top: 0em;
    border-radius: 100%;
    width: 2.5em;
    height: 2.5em;
    background-color: var(--color--yellow);
}

/*carousel*/
/*
.design__media{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 26%;
    gap: 5%;
    
    overflow-x: auto;
    overscroll-behavior-inline: contain;
}
.design__media__item img{
    inline-size: 90%;
    aspect-ratio: 3/4;
    border-radius: 2em;
    padding:  5% 5% 25% 5%;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(60, 95, 95, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.design__snaps{
    scroll-snap-type: inline mandatory;
}
.design__snaps > * {
    scroll-snap-align: start;
}
*/
/*
.design__media {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5%;

}

.design__media__item img {
    inline-size: 90%;
    aspect-ratio: 3/4;
    object-fit: cover;
    padding: 5%;


    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(60, 95, 95, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 2em;
    border: 1px solid rgba(255, 255, 255, 0.18);

    position: relative;
    z-index: 10;
}

.design__media__item h3 {
    position: absolute;
}
*/
/**/
.gallery__container h1 {
    font-family: 'Marck Script', cursive;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
    font-size: 4em;
}

.gallery__box {
    background-color: transparent;
    width: 90%;
    padding: 5%;
    transition: all ease-in-out 500ms;
    border-radius: 2em;
    border: 0.1em solid transparent;
    height: 100%;
    object-fit: contain;
    gap: 5%;
}

.gallery__box:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 0.1em solid rgba(255, 255, 255, 0.3);
}

.gallery__box img {
    inline-size: 100%;
    border-radius: 2%;
    height: 100%;
    object-fit: cover;
}
.gallery__box video {
    border-radius: 2%;
}
/*trio*/
.gallery__trio {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 30%;
  
}


/*duo12*/
.gallery__duo12 {
    display: grid;
    grid-template-columns:repeat(2,1fr)
}

.gallery__duo12__item2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
}



/*single video*/
.gallery__singleVideo{
    position: relative;
}
.gallery__signleVideo__hover{
    position: absolute;
    border-radius: 2em;
    width: 15em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(250,250,250,0.8);
}


.gallery__video__cursor {
    width: 5em;
    height: 5em;
    background-color: var(--color--darkgreen);
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    color: var(--color--wave);
    position: absolute;
    z-index: 10;
    transition: all ease-in-out 100ms;
}

.gallery__singleVideo:hover .gallery__video__cursor {
    display: flex;
}

/*Duo 11*/
.gallery__duo11 {
    grid-auto-flow: column;
    display: grid;
    grid-auto-columns: 47.5%;
  
}

/*arrow up*/
.design__arrowUp {
    width: 3em;
    height: 3em;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    border-radius: 100%;
    position: fixed;
    z-index: 99;
    right: 2%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;

    transition: ease-in-out 300ms;
}

.design__arrowUp p {
    font-size: 1.5em;
    font-weight: 500;
    
}
.design__arrowUp p:after{
    content: attr(data-content);
    position: absolute;
    top:100%;
    display: block; 
}