.container{
  overflow: hidden;
}
/*lotus*/
.lotus__1,
.lotus__2,
.lotus__3,
.lotus__4 {
  opacity: 0;
}

.lotus__1{
    width: 18vw;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 55%;
    left: 15%;
    opacity: 0.5;
    animation: float 2s infinite ease-in-out , fadeIn 2s forwards;
  }
  .lotus__2{
    width: 15vw;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 60%;
    right: 18%;
    transform:scaleX(-1);
    animation: float 2.5s infinite ease-in-out, fadeIn 2s forwards;
  }
  .lotus__3{
    width: 14vw;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 65%;
    left: 2%;
    animation: float1 2.5s infinite ease-in-out, fadeIn 2s forwards;
  }
  .lotus__4{
    width: 13vw;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 68%;
    right: 4%;
    transform: rotate(180deg);
    opacity: 0.7;
    animation: float 3s infinite ease-in-out, fadeIn 2s forwards;
  }
  @keyframes float {
    0%{
      margin-top: 0px;
    }
    50%{
      margin-top: -10px;
    }
    100%{
      margin-top: 0px;
    }
    
  }
  @keyframes float1 {
    0%{
      margin-left: 0px;
    }
    50%{
      margin-left: -10px;
    }
    100%{
      margin-left: 0px;
    }
    
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }